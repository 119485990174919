<template>
  <div class="wrap">
    <h1>Hier feiern wir</h1>
    <div class="locations">
      <div>
        <h2>Die Trauung</h2>
        <p>In dieser Kirche waren wir schon oft, noch viel häufiger aber besuchten wir deren Kirchturm. Da das dort zu findende Turmzimmer der KjG leider nicht genügend Plätze für alle unsere Gäste bietet, findet unsere Trauung nebenan in der St. Nikolaus Kirche in Gerbrunn statt.</p>
      </div>
      <iframe id="map"  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5152.638188539181!2d9.979181941020256!3d49.78006398438692!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a28f7aef14af47%3A0xc8b7f323f1db93ff!2sKath.%20Pfarrkirche%20St.%20Nikolaus!5e0!3m2!1sde!2sde!4v1731795885202!5m2!1sde!2sde"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <p>St. Nikolaus Kirche | Randersackererstraße | 97218 Gerbrunn</p>
    </div>

    <div class="locations">
      <div>
        <h2>Die Feier</h2>
        <p>Unsere Feier findet im „One-O-Two“ im Gewerbegebiet ConneKT in Kitzingen statt. Direkt an der Location befinden sich genügend Parkplätze und ihr könnt eure Autos ohne Probleme bis Sonntagabend dort stehen lassen.</p>
      </div>
      <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1923.6676267802206!2d10.189536534721544!3d49.74483582046672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a2631071acf459%3A0x1738c81c651a5124!2s%22One-o-two%22%20Veranstaltungslocation!5e0!3m2!1sde!2sde!4v1731799355660!5m2!1sde!2sde" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <p>One-O-Two | ConneKT 3 | 97318 Kitzingen</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LocationSite',
  components: {
  },
  props: {
    msg: String
  },
  created() {
     
  },
  data() {
    return {
    }
  },
  methods: { 

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 80vw;
}
p{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
h1{
  font-size: 30pt;
}
.locations{
  margin-bottom: 50px;
}
#map{
  width: 70vw;
  height: 70vw;
  border-radius: 10%;
  border: solid 2px #3a6090;
}

#map:hover{
  width: 70vw;
  height: 70vw;
  border-radius: 10%;
}

</style>
