<template>
  <div :class="mtype" class="font" @click="showdelete">
     <p>{{ msg }}</p> 
     <svg v-if="delete" @click="deleteM" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#3a6090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#3a6090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#3a6090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#3a6090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#3a6090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>


  </div>
</template>

<script>
export default {
  name: 'chatBubble',
  props: {
    msg: String,
    mtype: String
  },
  data() {
    return {
      delete: false,
    }
  },
  methods: {
    showdelete(){
      if(this.mtype==='guest message'){
        this.delete = !this.delete;
      }
    },
    deleteM(){
      this.$emit('delete-Antwort', { msg: this.msg, mtype: this.mtype });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div{
  flex: 0 1 auto;
  margin-bottom: 20px;
  min-height: 50px auto;
  max-width: 60%;
  align-content: center;
}
p{
  margin: 0px;
  padding:0px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.guest{
  background: #f9dcdd;
  color: #3a6090;
  align-self: flex-end;
  border-top-left-radius: 25px;
  border-top-right-radius: 0px;
  animation: fadeinright 0.8s;
}
.our {
  background: #3a6090;
  color: #FFFFff;
  align-self: flex-start;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  animation: fadeinleft 0.8s;
}
.font{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
.message{
  width: auto;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  
}

@keyframes fadeinleft {
    from { opacity: 0; transform: translateX(-20%); }
    to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeinright {
    from { opacity: 0; transform: translateX(20%); }
    to { opacity: 1; transform: translateX(0); }
}
</style>
