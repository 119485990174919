<template>
  <div class="logowrap">
    <div class="header">
      <div class="logowrap2>">
        <svg id="blatt" :class="{animationblatt: verified}" version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" 
            viewBox="0 0 68.101 124.818" overflow="visible" enable-background="new 0 0 68.101 124.818" xml:space="preserve">
          <g>
            <path fill="#68A18F" d="M3.06,54.993c7.333-30,37.334-41.333,63.334-53.333c-6,12.667-2,23.333-2,36.667
              c0.666,10.667-1.334,20.667-4,31.333c-3.334,13.333-8,20-17.334,30c-3.615,3.874-6.516,8.433-10.379,12.068
              c-1.824,1.717-3.961,2.714-5.823,4.432c-2.043,1.884-4.048,3.821-6.391,5.337c-1.473,0.953-3.091,2.158-4.903,1.459
              c-2.613-1.008-0.932-3.793-1.234-5.547c-0.469-2.721-1.986-5.599-2.953-8.185c-1.94-5.192-3.959-10.359-5.676-15.632
              C2.221,82.899,0.398,71.856,2.003,60.635C2.274,58.74,2.63,56.858,3.06,54.993z"/>
            <g>
              <path fill="none" d="M3.06,54.993c7.333-30,37.334-41.333,63.334-53.333c-6,12.667-2,23.333-2,36.667
                c0.666,10.667-1.334,20.667-4,31.333c-3.334,13.333-8,20-17.334,30c-3.615,3.874-6.516,8.433-10.379,12.068
                c-1.824,1.717-3.961,2.714-5.823,4.432c-2.043,1.884-4.048,3.821-6.391,5.337c-1.473,0.953-3.091,2.158-4.903,1.459
                c-2.613-1.008-0.932-3.793-1.234-5.547c-0.469-2.721-1.986-5.599-2.953-8.185c-1.94-5.192-3.959-10.359-5.676-15.632
                C2.221,82.899,0.398,71.856,2.003,60.635C2.274,58.74,2.63,56.858,3.06,54.993z"/>
              <path fill="#68A18F" d="M4.32,55.459c8.342-29.377,37.521-40.678,62.793-52.284c-0.765-0.699-1.528-1.398-2.293-2.097
                c-6.746,15.244-1,32.17-2.316,48.192c-1.346,16.375-5.896,32.271-16.641,44.872c-5.449,6.392-10.809,14.204-17.749,18.946
                c-3.372,2.304-6.036,5.407-9.498,7.626c-3.61,2.314-2.454-2.784-2.649-3.595c-0.45-1.873-1.047-3.601-1.77-5.393
                c-7.4-18.348-13.851-36.627-9.504-56.58c0.45-2.066-2.828-2.352-3.268-0.31c-4.358,20.258,1.948,39.723,9.775,58.313
                c1.02,2.423,1.522,4.578,1.22,7.154c-0.122,1.042,0.204,1.833,0.658,2.728c0.955,1.879,4.104,2.194,5.78,1.322
                c3.902-2.028,6.877-5.094,10.216-7.886c3.525-2.949,6.73-5.449,9.678-9.052c5.429-6.635,11.608-12.767,16.21-20.035
                c4.442-7.016,6.616-15.139,8.42-23.158c2.227-9.907,3.164-19.618,2.585-29.765c-0.635-11.118-2.667-21.633,1.998-32.217
                c0.614-1.393-1.022-2.684-2.293-2.097C40.171,11.902,8.919,24.081,1.8,54.527C1.415,56.173,3.84,57.153,4.32,55.459z"/>
            </g>
          </g>
        </svg>
        <svg id="blatt2" :class="{animationblatt2: verified}" version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" width="68.101" height="124.818"
            viewBox="0 0 68.101 124.818" overflow="visible" enable-background="new 0 0 68.101 124.818" xml:space="preserve">
          <g>
            <path fill="#68A18F" d="M3.06,54.993c7.333-30,37.334-41.333,63.334-53.333c-6,12.667-2,23.333-2,36.667
              c0.666,10.667-1.334,20.667-4,31.333c-3.334,13.333-8,20-17.334,30c-3.615,3.874-6.516,8.433-10.379,12.068
              c-1.824,1.717-3.961,2.714-5.823,4.432c-2.043,1.884-4.048,3.821-6.391,5.337c-1.473,0.953-3.091,2.158-4.903,1.459
              c-2.613-1.008-0.932-3.793-1.234-5.547c-0.469-2.721-1.986-5.599-2.953-8.185c-1.94-5.192-3.959-10.359-5.676-15.632
              C2.221,82.899,0.398,71.856,2.003,60.635C2.274,58.74,2.63,56.858,3.06,54.993z"/>
            <g>
              <path fill="none" d="M3.06,54.993c7.333-30,37.334-41.333,63.334-53.333c-6,12.667-2,23.333-2,36.667
                c0.666,10.667-1.334,20.667-4,31.333c-3.334,13.333-8,20-17.334,30c-3.615,3.874-6.516,8.433-10.379,12.068
                c-1.824,1.717-3.961,2.714-5.823,4.432c-2.043,1.884-4.048,3.821-6.391,5.337c-1.473,0.953-3.091,2.158-4.903,1.459
                c-2.613-1.008-0.932-3.793-1.234-5.547c-0.469-2.721-1.986-5.599-2.953-8.185c-1.94-5.192-3.959-10.359-5.676-15.632
                C2.221,82.899,0.398,71.856,2.003,60.635C2.274,58.74,2.63,56.858,3.06,54.993z"/>
              <path fill="#68A18F" d="M4.32,55.459c8.342-29.377,37.521-40.678,62.793-52.284c-0.765-0.699-1.528-1.398-2.293-2.097
                c-6.746,15.244-1,32.17-2.316,48.192c-1.346,16.375-5.896,32.271-16.641,44.872c-5.449,6.392-10.809,14.204-17.749,18.946
                c-3.372,2.304-6.036,5.407-9.498,7.626c-3.61,2.314-2.454-2.784-2.649-3.595c-0.45-1.873-1.047-3.601-1.77-5.393
                c-7.4-18.348-13.851-36.627-9.504-56.58c0.45-2.066-2.828-2.352-3.268-0.31c-4.358,20.258,1.948,39.723,9.775,58.313
                c1.02,2.423,1.522,4.578,1.22,7.154c-0.122,1.042,0.204,1.833,0.658,2.728c0.955,1.879,4.104,2.194,5.78,1.322
                c3.902-2.028,6.877-5.094,10.216-7.886c3.525-2.949,6.73-5.449,9.678-9.052c5.429-6.635,11.608-12.767,16.21-20.035
                c4.442-7.016,6.616-15.139,8.42-23.158c2.227-9.907,3.164-19.618,2.585-29.765c-0.635-11.118-2.667-21.633,1.998-32.217
                c0.614-1.393-1.022-2.684-2.293-2.097C40.171,11.902,8.919,24.081,1.8,54.527C1.415,56.173,3.84,57.153,4.32,55.459z"/>
            </g>
          </g>
        </svg>

        <svg class="logo" :class="{animation: verified}" version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
            width="233" height="202" viewBox="-0.103 -0.496 233 202" enable-background="new -0.103 -0.496 233 202" xml:space="preserve">
          <defs>
          </defs>
          <g>
            <path fill="#395F8F" d="M97.92,74.117c2-11-6.5-14.5-13.5-21.5c-8.5-8.5-15.5-16.5-27.5-20s-31-4-40.5,5c-11,10.5-5,24.5-8.5,37.5
              c-3,13.5-16.5,38.5,0.5,45.5c17.5,8,41,0.5,56.5-9c-14,9.5-31,17.5-33,36.5c-1.5,12.5,7,29,17,35.5c5,3.5,10.5,4,16,6.5
              c7.5,4,14.5,8.5,23,10c9,1.5,23.5,0,32-3c12-5,31.5-21,31-35c-0.5-7.5-4.5-11.5-8.5-17c-5.5-6.5-4.5-11.5-7-19.5
              c1,7.5,4.5,13.5,8.5,20c4.5,7,5,7.5,14,6.5c11-1,20.5,1,31-2c14-3.5,31-2,39.5-16c8-13,0-25.5-2-38.501c-1.5-10-0.5-20.5-1.5-30
              c-1.5-11-7-10.5-17-11c-9.5-0.5-18.5-1.5-27.5,3.5c-8.5,4.5-15.5,13-24.5,16.5c-2.5-20,62.5-61.5,14-73c-12.5-3-28.5-1-41.5-0.5
              c-13.5,0.5-26.5-1-37,9c-17,16-4.5,37,2,54.5"/>
          </g>
          <g>
            <path fill="#F9DCDD" d="M137.13,95.114c-7.473-7.471-18.205-15.06-29.504-12.675c-9.226,1.947-16.969,13.474-11.496,21.676
              c1.001,1.5,5,1,6.5,2c3.5,2,4,4,6.5,6c5.5,6,7,6.5,15,3.5c3.5-1.5,12-4.5,15-7.5C144.13,103.615,142.13,99.614,137.13,95.114z"/>
          </g>
          <g>
            <path fill="#FFE605" d="M126.397,100.114c-0.5-8-17-13-20.5-5c-5.5,13,28.5,16,20,2.5"/>
          </g>
        </svg>
      </div>
      <div id="titlwrap">
        <h1 id="title">Johanna & Felix</h1>
        <p id="subtitle">10. Mai 2025</p>
      </div>
      <router-link :to="{path: '/', hash: '#nav'}" style="">
        <div id="burgernav">
          <svg style="margin-top: -12px;" viewBox="0 0 24 24" width="30px" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#3a6090"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#3a6090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
      </router-link>  
      </div>
      <div class="abfrage" v-if="verified===false">
        <h1>Hallo {{ vorname }}</h1>
        <p>um sicherzustellen, dass nur unsere Gäste Zugang haben, bitten wir dich, deinen Namen zur Verifizierung einzugeben.</p>
        <div  @keydown.enter="checkGuest" id="wrapinput">
          <input v-model="vorname" id="Name" placeholder="Vorname" />
          <input v-model="nachname" id="Name" placeholder="Nachname" />
          <input type="submit" hidden />
          <div class="check" @click="checkGuest">
            Los gehts!
          </div>
        </div>
      </div>
    <div class="hello" v-if="verified">
      <Nav id="nav" :admin="admin" />
      <Flower class="flower" flower="first"/>
      <Chat id="RSVP" :vorname="vorname" :nachname="nachname"/>
      <Flower class="flower" flower="second"/>
      <Locations  id="locations"/>
      <Flower class="flower" flower="third"/>
      <trauzeugen id="trauzeugen"/>
      <answers id="answers" v-if="admin" />
    </div>
  </div>
</template>

<script>
import Chat from './chat.vue'
import Nav from './Nav.vue'
import Locations from './Locations.vue';
import axios from 'axios'
import Flower from './flower.vue';
import trauzeugen from './trauzeugen.vue';
import answers from './answers.vue';

export default {
  name: 'HomeSite',
  components: {
    Chat,
    Nav,
    Locations,
    Flower,
    trauzeugen,
    answers
  },
  props: {
    msg: String
  },
  created() {
    this.fetchGuests(); 
  },
  data() {
    return {
      vorname: '',
      nachname:'',
      guests: [],
      admin: false,
      verified: false,
    }
  },
  methods: { 
    fetchGuests() {
      axios.get('../../getGuests.php')
        .then(response => {
          this.guests = response.data;
        })
        .catch(error => {
          console.error('Error fetching guests:', error);
        });
    },
    checkGuest(){
      if(Array.isArray(this.guests)){
        this.vorname=this.vorname.trim();
        this.nachname=this.nachname.trim();
        const lowercaseGuests = this.guests;
        const exists = lowercaseGuests.some(guest =>  ( guest.Vorname.toLowerCase() === this.vorname.toLowerCase() &&
                                                      guest.Nachname.toLowerCase() === this.nachname.toLowerCase() ) || 
                                                      ( guest.Spitz1.toLowerCase() === this.vorname.toLowerCase() &&
                                                      guest.Nachname.toLowerCase() === this.nachname.toLowerCase() )                                                      
                                                    );
        if(exists){
          console.log(this.verified);
          this.verified = true;
          console.log(this.verified);
        }
      }
      if(this.vorname.toLowerCase() === "joundfeliximmai"){
        this.verified = true;
        this.admin  = true;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html{
  scroll-padding-top: 100px;
}
.hello{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.header{
  top: 0px;
  z-index: 90;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;

}
#titlwrap{
  position: absolute;
  width: 100vw;
  flex-direction: column;
}
#title{
  margin: 0px;
}

#subtitle{
  margin-top:-10px;
  margin: 0px;
  font-size: 9pt;
  letter-spacing: 3pt;
}
.abfrage{
  position: relative;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  z-index:99;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;

}
#wrapinput{
  display: flex;
  flex-direction: column;
  align-items: center;
}
input{
  height: 30px;
  width: 200px; 
  border-radius: 25px;
  border: solid 1px #FFFFFF;
  background: none;
  margin: 10px;
  color: #FFFFFF;
  text-align: center;
}
#RSVP{
}
#nav{

}
.check{
  height: 30px;
  width: 200px;
  border-radius: 25px;
  border: solid 1px #f9dcdd;
  background: #f9dcdd;
  margin: 10px;
  color: #3a6090;
  justify-content: center;
  align-items: center;
  display: flex;
}
.logowrap{
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: start;
}
.logo{
  z-index: 20;
  margin-top: 0px;
  height: 100px;
  margin: 0px;
  position: relative;
  image-rendering: crisp-edges;
  transform-origin: center;
  transform: scale(2000%) translateY(-12px) translateX(10px) rotate(90deg);
}
.logowrap2{
  height:100px;
  max-width: 50px;
}
#burgernav{
  width: 50px;
  height: 100px;
  display: flex;
  justify-content: center;
  z-index: 10;
  position: relative;
}


.flower{
  overflow: hidden;
}
.animation{
  animation: flower 3s;
  animation-fill-mode: forwards;
  margin: 0px;
  width: auto;
}
@keyframes flower {
  0% {
    transform: scale(2000%) translateY(-28px) rotate(90deg);
  }
  100% {
    transform: scale(50%) translateY(0px) rotate(0deg) translateX(0px);
  }
}


#blatt{
  z-index: 1;
  margin-top: 0px;
  height: 100px;
  width: 100px;
  top:0px;
  left:0px;
  position: absolute;
  image-rendering: crisp-edges;
  transform-origin: center;
  transform: scale(2000%) translateY(20px) translateX(0px) rotate(90deg);
}
.animationblatt{
  animation: blatt 5s;
  animation-fill-mode: forwards;
  margin: 0px;
  width: auto;
}

@keyframes blatt {
  0%{
    transform: scale(0%) translateY(0px) translateX(0px) rotate(120deg);
  }
  100% {
    transform: scale(20%) translateY(-130px) translateX(-45px) rotate(110deg);
  }
}

#blatt2{
  z-index: 1;
  margin-top: 0px;
  height: 100px;
  width: 100px;
  top:0px;
  left:0px;
  position: absolute;
  image-rendering: crisp-edges;
  transform-origin: center;
  transform: scale(2000%) translateY(20px) translateX(0px) rotate(90deg);
}
.animationblatt2{
  animation: blatt2 5s;
  animation-fill-mode: forwards;
  margin: 0px;
  width: auto;
}

@keyframes blatt2 {
  0%{
    transform: scale(0%) translateY(0px) translateX(0px) rotate(-90deg);
  }
  100% {
    transform: scale(20%) translateY(80px) translateX(145px) rotate(-100deg);
  }
}

input::placeholder {
      color: white; /* Platzhalterfarbe auf weiß setzen */
      opacity: 1; /* Sicherstellen, dass die Farbe vollständig deckend ist */
      font-family: "Poppins", serif;
  font-weight: 500;
    
    }
    input::-webkit-input-placeholder { /* Chrome, Safari, Opera */
      color: white;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
    input::-moz-placeholder { /* Firefox 19+ */
      color: white;
      opacity: 1;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
    input:-ms-input-placeholder { /* IE 10+ */
      color: white;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
    input::-ms-input-placeholder { /* Edge */
      color: white;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
</style>
