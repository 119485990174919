<template>
  <div class="wrap">
    <h1>Unsere Trauzeugen</h1>
    <div class="container">
      <div id="lubboimg">
      </div>
      <div class="info">
        <h2>Philipp</h2>
        <a href="https://wa.me/4915146412386?text=Hallo, Philipp"> 
          <p>
            <svg id='WhatsApp_24' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect width='24' height='24' stroke='none' fill='#000000' opacity='0'/>
              <g transform="matrix(0.83 0 0 0.83 12 12)" >
              <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: #3a6090; fill-rule: nonzero; opacity: 1;" transform=" translate(-15, -15)" d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z" stroke-linecap="round" />
              </g>
            </svg>
            +49 1514 6412386
          </p>
        </a>
        <a href="mailto:trauzeugen@joundfelix.info">
          <p>
            <svg id='Secured_Letter_24' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect width='24' height='24' stroke='none' fill='#000000' opacity='0'/>
              <g transform="matrix(0.4 0 0 0.4 12 12)" >
              <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: #3a6090; fill-rule: nonzero; opacity: 1;" transform=" translate(-25, -25)" d="M 0 7 L 0 9.78125 C 3.011719 12.402344 20.289063 27.464844 21.65625 28.65625 C 23.0625 29.882813 24.464844 30 25 30 C 25.535156 30 26.9375 29.882813 28.34375 28.65625 C 29.761719 27.421875 48.046875 11.480469 50 9.78125 L 50 7 Z M 0 12.4375 L 0 37.53125 L 15.125 25.59375 C 10.222656 21.328125 3.230469 15.25 0 12.4375 Z M 50 12.4375 C 46.949219 15.09375 39.847656 21.265625 34.875 25.59375 L 50 37.5 Z M 16.65625 26.9375 L 0 40.0625 L 0 43 L 50 43 L 50 40.03125 L 33.34375 26.9375 C 31.40625 28.625 30.011719 29.847656 29.65625 30.15625 C 27.738281 31.824219 25.757813 32 25 32 C 24.242188 32 22.261719 31.828125 20.34375 30.15625 C 19.992188 29.851563 18.597656 28.628906 16.65625 26.9375 Z" stroke-linecap="round" />
              </g>
              </svg>
              trauzeugen@joundfelix.info
          </p>
        </a>
      </div>
    </div>
    <div class="container">
      <div id="lenaimg">
      </div>
      <div class="info">
        <h2 style="">Lena</h2>
        <a href="https://wa.me/491783275224?text=Hallo, Lena"> 
          <p>
            <svg id='WhatsApp_24' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect width='24' height='24' stroke='none' fill='#000000' opacity='0'/>
              <g transform="matrix(0.83 0 0 0.83 12 12)" >
              <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: #3a6090; fill-rule: nonzero; opacity: 1;" transform=" translate(-15, -15)" d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z" stroke-linecap="round" />
              </g>
            </svg>
            +49 1783 275224
          </p>
        </a>
        <a href="mailto:trauzeugen@joundfelix.info">
          <p>
            <svg id='Secured_Letter_24' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect width='24' height='24' stroke='none' fill='#000000' opacity='0'/>
              <g transform="matrix(0.4 0 0 0.4 12 12)" >
              <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: #3a6090; fill-rule: nonzero; opacity: 1;" transform=" translate(-25, -25)" d="M 0 7 L 0 9.78125 C 3.011719 12.402344 20.289063 27.464844 21.65625 28.65625 C 23.0625 29.882813 24.464844 30 25 30 C 25.535156 30 26.9375 29.882813 28.34375 28.65625 C 29.761719 27.421875 48.046875 11.480469 50 9.78125 L 50 7 Z M 0 12.4375 L 0 37.53125 L 15.125 25.59375 C 10.222656 21.328125 3.230469 15.25 0 12.4375 Z M 50 12.4375 C 46.949219 15.09375 39.847656 21.265625 34.875 25.59375 L 50 37.5 Z M 16.65625 26.9375 L 0 40.0625 L 0 43 L 50 43 L 50 40.03125 L 33.34375 26.9375 C 31.40625 28.625 30.011719 29.847656 29.65625 30.15625 C 27.738281 31.824219 25.757813 32 25 32 C 24.242188 32 22.261719 31.828125 20.34375 30.15625 C 19.992188 29.851563 18.597656 28.628906 16.65625 26.9375 Z" stroke-linecap="round" />
              </g>
              </svg>
              trauzeugen@joundfelix.info
          </p>
        </a>
      </div>

    </div>
    
  </div>
</template>

<script>

export default {
  name: 'TrauzeugenSite',
  components: {
  },
  props: {
    msg: String
  },
  created() {
     
  },
  data() {
    return {
    }
  },
  methods: { 

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  width: 100vw;
}
p{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0px;
  margin-top: 10px;
}
h1{
  font-size: 30pt;
}
h2{
  margin:0px;
  text-align:left;
}
a{
  text-decoration: none;
  color: inherit;
}
.container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
}
.info{
  display: flex;
  flex-direction: column;
  align-content: start;
  margin-left: 10px;
}
#lubboimg{
  background: url(../assets/lubbo.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: solid 2px #3a6090;
  width: 80px;
  height: 80px;
}
#lenaimg{
  background: url(../assets/lena.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: solid 2px #3a6090;
  width: 80px;
  height: 80px;
}

</style>
