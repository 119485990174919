<template>
  <div :class="wrapflower">
    <svg :class="flower" version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
          width="233" height="202" viewBox="-0.103 -0.496 233 202" enable-background="new -0.103 -0.496 233 202" xml:space="preserve">
        <defs>
        </defs>
        <g>
          <path fill="#395F8F" d="M97.92,74.117c2-11-6.5-14.5-13.5-21.5c-8.5-8.5-15.5-16.5-27.5-20s-31-4-40.5,5c-11,10.5-5,24.5-8.5,37.5
            c-3,13.5-16.5,38.5,0.5,45.5c17.5,8,41,0.5,56.5-9c-14,9.5-31,17.5-33,36.5c-1.5,12.5,7,29,17,35.5c5,3.5,10.5,4,16,6.5
            c7.5,4,14.5,8.5,23,10c9,1.5,23.5,0,32-3c12-5,31.5-21,31-35c-0.5-7.5-4.5-11.5-8.5-17c-5.5-6.5-4.5-11.5-7-19.5
            c1,7.5,4.5,13.5,8.5,20c4.5,7,5,7.5,14,6.5c11-1,20.5,1,31-2c14-3.5,31-2,39.5-16c8-13,0-25.5-2-38.501c-1.5-10-0.5-20.5-1.5-30
            c-1.5-11-7-10.5-17-11c-9.5-0.5-18.5-1.5-27.5,3.5c-8.5,4.5-15.5,13-24.5,16.5c-2.5-20,62.5-61.5,14-73c-12.5-3-28.5-1-41.5-0.5
            c-13.5,0.5-26.5-1-37,9c-17,16-4.5,37,2,54.5"/>
        </g>
        <g>
          <path fill="#F9DCDD" d="M137.13,95.114c-7.473-7.471-18.205-15.06-29.504-12.675c-9.226,1.947-16.969,13.474-11.496,21.676
            c1.001,1.5,5,1,6.5,2c3.5,2,4,4,6.5,6c5.5,6,7,6.5,15,3.5c3.5-1.5,12-4.5,15-7.5C144.13,103.615,142.13,99.614,137.13,95.114z"/>
        </g>
        <g>
          <path fill="#FFE605" d="M126.397,100.114c-0.5-8-17-13-20.5-5c-5.5,13,28.5,16,20,2.5"/>
        </g>
      </svg>
      
      <svg :class="leave" class="leave"  version="1.0" width="66.822" height="135.787"
        viewBox="0 0 66.822 135.787" overflow="visible" enable-background="new 0 0 66.822 135.787" xml:space="preserve">
      <g>
        <path fill="#68A18F" d="M46.599,24.502c-0.442-1.497-0.92-2.98-1.491-4.428c-2.036-5.148-2.699-10.927-5.813-15.604
          C35.805-0.77,29.582,1.76,24.893,4.45c-5.626,3.226-9.812,9.059-12.947,14.605C5.125,31.123,1.043,46.235,1.539,60.149
          c0,15.333,9.334,27.333,11.334,42.667c1.332,11.333,6.666,20.666,8.666,31.333c3.334-18.667,13.334-20.667,26-33.333
          c11.334-11.334,20-22,16.666-38.667c-2.143-9.43-8.389-16.332-12.99-24.531C48.925,33.539,47.916,28.961,46.599,24.502z"/>
        <g>
          <path fill="none" d="M46.599,24.502c-0.442-1.497-0.92-2.98-1.491-4.428c-2.036-5.148-2.699-10.927-5.813-15.604
            C35.805-0.77,29.582,1.76,24.893,4.45c-5.626,3.226-9.812,9.059-12.947,14.605C5.125,31.123,1.043,46.235,1.539,60.149
            c0,15.333,9.334,27.333,11.334,42.667c1.332,11.333,6.666,20.666,8.666,31.333c3.334-18.667,13.334-20.667,26-33.333
            c11.334-11.334,20-22,16.666-38.667c-2.143-9.43-8.389-16.332-12.99-24.531C48.925,33.539,47.916,28.961,46.599,24.502z"/>
          <path fill="#68A18F" d="M47.807,24.29c-0.977-3.652-2.288-7.194-3.311-10.834c-0.935-3.328-1.889-7.188-4.055-9.957
            c-3.406-4.355-7.991-4.083-12.783-2.21C13.64,6.768,6.417,24.115,2.65,37.487C-1.767,53.171-0.487,67.913,5.188,82.98
            c3.178,8.437,5.101,16.577,6.943,25.357c1.868,8.904,5.952,17.167,7.753,26.104c0.394,1.95,2.971,1.673,3.326-0.134
            c2.221-11.271,8.432-17.643,17.022-24.622c8.169-6.637,16.188-14.315,21.677-23.375c5.04-8.318,6.451-18.979,3.064-28.192
            c-1.951-5.309-4.922-9.997-7.997-14.711c-4.091-6.268-6.628-12.504-8.89-19.612c-0.563-1.77-3.574-0.795-3.112,0.994
            c1.523,5.909,3.044,11.195,6.239,16.475c3.106,5.133,6.635,9.945,9.185,15.4c5.401,11.556,1.957,23.576-5.385,33.241
            c-6.405,8.432-14.668,14.861-22.616,21.708c-7.005,6.034-10.805,13.338-12.531,22.378c1.108-0.045,2.217-0.089,3.326-0.134
            c-1.3-6.258-3.533-12.206-5.565-18.249c-2.353-6.993-2.876-14.239-4.81-21.311c-1.62-5.924-4.128-11.586-6.208-17.354
            c-2.767-7.672-3.704-15.259-3.282-23.389c0.751-14.489,6.095-29.663,14.982-41.159c3.396-4.393,12.222-12.058,18.323-8.575
            c3.268,1.865,4.564,8.563,5.501,11.745c0.949,3.22,2.262,6.293,3.355,9.463C45.941,26.334,48.162,25.62,47.807,24.29z"/>
        </g>
      </g>
      </svg>

  </div>
</template>

<script>

export default {
  name: 'NavSite',
  components: {
  },
  props: {
    flower: String,
  },
  computed: { 
    wrapflower() { 
      return `wrap${this.flower}`; 
    },
    leave(){
      return `leave${this.flower}`;
    }
  },
  created() {
     
  },
  data() {
    return {
    }
  },
  methods: { 

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.first{
  position: absolute;
  transform: scale(200%) translateX(100px) translateY(50px) rotate(40deg);
  animation: first 20s infinite;
  overflow: hidden;
}
@keyframes first {
  0% { transform: scale(230%) translateX(100px) translateY(0px) rotate(0deg);} 
  50% { transform: scale(200%) translateX(100px) translateY(10px) rotate(20deg);} 
  100% { transform: scale(230%) translateX(100px) translateY(0px) rotate(0deg);} 
}
.wrapfirst{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  box-sizing: border-box;
  width: 100vw;
  position: relative;
  z-index: -10;
  margin-top: -30vh;
}
.leavefirst{
  position: absolute;
  transform: scale(230%) translateX(-100px) translateY(-80px) rotate(90deg);
  animation: firstleave 20s infinite;
  overflow: hidden;
}
@keyframes firstleave {
  0% { transform: scale(230%) translateX(-100px) translateY(-60px) rotate(90deg);} 
  50% { transform: scale(200%) translateX(-100px) translateY(-55px) rotate(100deg);} 
  100% { transform: scale(230%) translateX(-100px) translateY(-60px) rotate(90deg);} 
}

.second{
  position: absolute;
  transform: scale(200%) translateX(100px) translateY(50px) rotate(40deg);
  animation: second 20s infinite;
  overflow: hidden;
}
@keyframes second {
  0% { transform: scale(230%) translateX(-100px) translateY(0px) rotate(0deg);} 
  50% { transform: scale(200%) translateX(-100px) translateY(10px) rotate(20deg);} 
  100% { transform: scale(230%) translateX(-100px) translateY(0px) rotate(0deg);} 
}
.wrapsecond{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  box-sizing: border-box;
  width: 100vw;
  position: relative;
  z-index: -10;
  margin-top: 10vh;
}

.leavesecond{
  position: absolute;
  transform: scale(230%) translateX(-100px) translateY(-80px) rotate(90deg);
  animation: secondleave 20s infinite;
  overflow: hidden;
}
@keyframes secondleave {
  0% { transform: scale(230%) translateX(100px) translateY(60px) rotate(90deg);} 
  50% { transform: scale(200%) translateX(100px) translateY(50px) rotate(100deg);} 
  100% { transform: scale(230%) translateX(100px) translateY(60px) rotate(90deg);} 
}


.third{
  position: absolute;
  transform: scale(200%) translateX(100px) translateY(50px) rotate(40deg);
  animation: first 20s infinite;
  overflow: hidden;
}
@keyframes third {
  0% { transform: scale(230%) translateX(100px) translateY(0px) rotate(0deg);} 
  50% { transform: scale(200%) translateX(100px) translateY(10px) rotate(20deg);} 
  100% { transform: scale(230%) translateX(100px) translateY(0px) rotate(0deg);} 
}
.wrapthird{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  box-sizing: border-box;
  width: 100vw;
  position: relative;
  z-index: -10;
  margin-top: -10vh;
}
.leavethird{
  position: absolute;
  transform: scale(230%) translateX(-100px) translateY(-80px) rotate(90deg);
  animation: thirdleave 20s infinite;
  overflow: hidden;
}
@keyframes thirdleave {
  0% { transform: scale(230%) translateX(-100px) translateY(-60px) rotate(90deg);} 
  50% { transform: scale(200%) translateX(-100px) translateY(-55px) rotate(100deg);} 
  100% { transform: scale(230%) translateX(-100px) translateY(-60px) rotate(90deg);} 
}


</style>
