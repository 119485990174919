<template>
  <div class="input font" id="jump">
    <div v-if="qtype==='input'" @keydown.enter="sendAntwort" id="wrapinput">
      <label v-if="text">{{ text }}</label>
      <textarea v-model="anwser" type="qtype" id="qid" :placeholder="localPlaceholder" class="font"/>
      <input type="submit" hidden />
    </div>
    <div v-if="qtype==='select'" @click="sendAntwort" id="wrapselect">
      <p>{{ text }}</p>
    </div>
    <div v-if="qtype==='check'" class="check" id="wrapcheck">
      <label v-for="item in selectable" :for="item" :key="item.id" class="checkrow">
        <input type="checkbox"  :id="item" :value="item" v-model="selecteditems"/>
        <span class="checkbox"></span> 
        <span class="label">{{ item }}</span>
      </label>
    </div>
    <div id="send" @click="sendAntwort" v-if="qtype!='select'">
      <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
        <path fill-rule="evenodd" d="M12 2a1 1 0 0 1 .932.638l7 18a1 1 0 0 1-1.326 1.281L13 19.517V13a1 1 0 1 0-2 0v6.517l-5.606 2.402a1 1 0 0 1-1.326-1.281l7-18A1 1 0 0 1 12 2Z" clip-rule="evenodd" transform="rotate(90 12 12)"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chatInput',
  props: {
    qtype: String,
    text: String,
    qid: String,
    placeholder: String,
    selectable: undefined
  },
  watch:{
    placeholder(){
      this.copyPlaceholder();
    }
  },
  mounted(){
    
  },
  data() {
    return {
      anwser: '',
      selecteditems: [],
      localPlaceholder: this.placeholder
    }
  },
  methods: {
    sendAntwort(){
      
      if(this.qtype==='input'){
        if(this.anwser!=''){
          if(this.placeholder==='deine E-Mail' && this.validateEmail()===false ){
            this.anwser='';
            this.localPlaceholder='die Mail ist nicht korrekt!';
          }else{
            this.$emit('add-Antwort', { qid: this.qid, text: this.anwser, mtype: 'guest message' });
          }
        }else{
          const input = document.getElementById('qid');
          input.focus();
        }
      }else if(this.qtype==='select'){
        this.$emit('add-Antwort', { qid: this.qid, text: this.text, mtype: 'guest message' });
      }else if(this.qtype==='check'){
        if(this.selecteditems.length === 0){
          this.$emit('add-Antwort', { qid: this.qid, text: 'Nichts', mtype: 'guest message' });
        }else{
          this.$emit('add-Antwort', { qid: this.qid, text: this.selecteditems, mtype: 'guest message' });
        }
      }
         
    },
    validateEmail() {
      if (!this.anwser.includes('@')) {
        return false;
      }else{
        return true;
      }
    },
    copyPlaceholder(){
      this.localPlaceholder=this.placeholder;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div{
  flex: 0 1 auto;
  align-content: center;
  min-height: 40px;
  max-width: 100%;
}
.input{
  animation: fadeinright 0.8s;
  flex-direction: row;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
#send{
  align-items: center;
  height: auto;
  display: flex;
  width: auto;
}

input, textarea{
  background: none;
  border: none;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  color: #3a6090;
  width: 100%;
  overflow-wrap: break-word;
  field-sizing: content;
  font-size: small;  
}
input:focus{
  outline:  none;
}
textarea:focus{
  outline:  none;
}
#wrapinput{
  width: auto;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 24px);
  flex: 1 1 auto;
  justify-content: center;
}
.font{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
p{
  margin: 0px;
  padding:0px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.check{
  text-align: left;
  padding-left:10px;
  display: flex;
  flex-direction: column;

}

.checkrow{
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
}
input[type="checkbox"]{
  display: none;
}

.checkbox{
  display: flex;
  position: relative;
  padding-right: 10px;
  top: -2px;
}
.checkbox::before{
  /*content: '\2661';*/
  content: '\2764';
  display: flex;
  position: absolute;
  font-size: 18px;
  color: red;
  height: 25px;
  width: 25px;
  top: 0px;
  left: 0px;
  transform: scale(0) rotateZ(180deg);
  transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
}


input[type="checkbox"]:checked + .checkbox::before {
  transform: scale(1) rotateZ(0deg);
}

input[type="checkbox"]:checked + .checkbox::after {
  transform: scale(0) rotateZ(0deg);
  transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
}

.checkbox::after {
  content: "";
  border: 2px solid #3a6090;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50%;
}


@keyframes fadeinright {
    from { opacity: 0; transform: translatey(20%); }
    to { opacity: 1; transform: translateX(0); }
}
</style>