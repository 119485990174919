<template>
  <Home msg="test" id="app"/>
  
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home,
  }
}
</script>

<style>
html{
  font-size: small;
  scroll-padding-top: 100px;
}
h1{
  font-family: "Forum", serif;
  font-weight: 400;
  font-style: normal;
}
h2{
  font-family: "Forum", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20pt;
}
#app {
  text-align: center;
  color: #3a6090;
  margin: 0px;
  padding: 0px;
}
body{
  margin: 0px;
  padding: 0px;
}


</style>
