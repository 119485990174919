<template>
  <div class="wrap">
      <h1>Antworten</h1>
      <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
      <p>Zusagen: {{ this.zusage }}</p>
      <p>Absagen: {{ this.absage }}</p>
      <div> 
        <div>
          <ul>
            <li v-for="answer in answers" :key="answer.UUID"> 
              <p>{{ answer.Name }} und {{ answer.Partner }} zugsagt: {{ answer.Zusage }}</p> 
            </li> 
          </ul> 
        </div>
      </div>
      <apexchart type="donut" :options="chartOptionsB" :series="seriesB"></apexchart>
      <apexchart type="donut" :options="chartOptionsW" :series="seriesW"></apexchart>
      <apexchart type="donut" :options="chartOptionsAF" :series="seriesAF"></apexchart>
  </div>
</template>

<script>
  import axios from 'axios'
  import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'AnswerSte',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    msg: String,
    guests: [],
  },
  async created() {
    await this.fetchAnswers();
    console.log(this.answers.length)
    this.zusage = this.answers.filter(item => item.Zusage === 1).length; 
    this.absage = this.answers.filter(item => item.Zusage === 0).length;
    this.series = [this.zusage, this.absage];
    this.seriesB =[
      this.answers.filter(item => item.Bier === 'Pils').length,
      this.answers.filter(item => item.Bier === 'Helles').length,
      this.answers.filter(item => item.Bier === 'Weißbier').length,
      this.answers.filter(item => item.Bier === 'Jagdherren').length
    ];
    this.seriesW =[
      this.answers.filter(item => item.Wein === 'Weißwein').length,
      this.answers.filter(item => item.Wein === 'Rotwein').length,
      this.answers.filter(item => item.Wein === 'Rotling oder Rosé').length
    ];
    this.seriesAF = Object.values(this.answers.reduce((acc, item) => { acc[item.Alkohlfrei] = (acc[item.Alkohlfrei] || 0) + 1; 
                                          return acc; }, {}));
    this.chartOptionsAF.labels = Object.keys(this.answers.reduce((acc, item) => { acc[item.Alkohlfrei] = (acc[item.Alkohlfrei] || 0) + 1; 
      return acc; }, {}));
  },
  data() {
    return {
      answers: [],
      zusage: 0,
      absage: 0,
      series: [0,0],
      chartOptions: { 
        labels: ['Zusage', 'Absage']
      },
      seriesB: [0,0],
      chartOptionsB: { 
        labels: ['Pils', 'Helles', 'Weißbier', 'Jagdherren']
      },
      seriesW: [0,0],
      chartOptionsW: { 
        labels: ['Weißwein', 'Rotwein', 'Rotling']
      }, 
      seriesAF: [0,0],
      chartOptionsAF: { 
        labels: ['', '', '']
      }, 
    }
  },
  computed: { 
  },
  methods: { 
    async fetchAnswers() {
      await axios.get('../../getAnswers.php')
        .then(response => {
          this.answers = response.data;
        })
        .catch(error => {
          console.error('Error fetching guests:', error);
        });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  width: 100vw;
}
p{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0px;
  margin-top: 10px;
}
h1{
  font-size: 30pt;
}
h2{
  margin:0px;
  text-align:left;
}
a{
  text-decoration: none;
  color: inherit;
}
.container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
}
.info{
  display: flex;
  flex-direction: column;
  align-content: start;
  margin-left: 10px;
}
#lubboimg{
  background: url(../assets/lubbo.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: solid 2px #3a6090;
  width: 80px;
  height: 80px;
}
#lenaimg{
  background: url(../assets/lena.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: solid 2px #3a6090;
  width: 80px;
  height: 80px;
}

</style>
