<template>
  <div class="wrap" >
    <h1 id="RSVP">Rückmeldung</h1> 
    <div class="wrapChat" >
      <chatbubble v-for="(answer, index) in answers" :key="index" :msg="answer.text" :mtype="answer.mtype" @delete-Antwort="deleteAntwort"/>
      <div v-if="typing" class="loader">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div v-if="typing===false" id="inputwrap" @change="scrollDown">
        <chatinput @add-Antwort="addAntwort"  v-for="(question, index) in questions" :key="index" :text="question.text" :qid="question.qid" :qtype="question.qtype" :placeholder="question.placeholder" :selectable="question.selectable" class="input" />
      </div>
      <div v-if="showCal">
        <svg @click="showCalRows" class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" clip-rule="evenodd"/>
        </svg>
        <div v-if="showCalRow" class="cals">
          <div onclick="location.href='https://calndr.link/e/iWbeklQ8VS?s=apple';"  class="calrow">
            <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M17.537 12.625a4.421 4.421 0 0 0 2.684 4.047 10.96 10.96 0 0 1-1.384 2.845c-.834 1.218-1.7 2.432-3.062 2.457-1.34.025-1.77-.794-3.3-.794-1.531 0-2.01.769-3.275.82-1.316.049-2.317-1.318-3.158-2.532-1.72-2.484-3.032-7.017-1.27-10.077A4.9 4.9 0 0 1 8.91 6.884c1.292-.025 2.51.869 3.3.869.789 0 2.27-1.075 3.828-.917a4.67 4.67 0 0 1 3.66 1.984 4.524 4.524 0 0 0-2.16 3.805m-2.52-7.432A4.4 4.4 0 0 0 16.06 2a4.482 4.482 0 0 0-2.945 1.516 4.185 4.185 0 0 0-1.061 3.093 3.708 3.708 0 0 0 2.967-1.416Z"/><title>Apple</title>
            </svg>
            Apple
          </div>
          <div onclick="location.href='https://calndr.link/e/iWbeklQ8VS?s=google';" class="calrow">
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z" clip-rule="evenodd"/><title>Google</title>
            </svg>
            Google
          </div> 
          <div onclick="location.href='https://calndr.link/e/iWbeklQ8VS?s=outlook';" class="calrow" id="jump">
            <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>Outlook</title> <rect width="24" height="24" fill="none"></rect> 
              <path d="M3,18l4-1.25V7l7-2V19.5L3.5,18.25,14,22l6-1.25V3.5L14,2,3,5.75Z"></path> </g>
            </svg>
            Outlook
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chatbubble from './chatbubble.vue'
import chatinput from './chatinput.vue'
import axios from 'axios'

export default {
  components: { 
    chatbubble, 
    chatinput
  },
  name: 'chatSite',
  props: {
    msg: String,
    vorname: String,
    nachname: String
  },
  data() {
    return {
      questions: [
        {qid: 'Q001', qtype: 'select', text: 'Rückmeldung starten', placeholder:''}
      ],
      answers: [
        {aid: 'A100', text: 'Hallo ' + this.vorname + ', gib uns doch Bescheid, ob du zu unserer Hochzeit kommst.', mtype: 'our message'}
      ],
      inputState: 'initial',
      typing: false,
      showCal: false,
      showCalRow: false,
      guests: [],
      userPartner: '',
      userMail: '',
      userName: '',
      zusage: true,
      kinder: '',
      essen:'',
      bier:'',
      wein:'',
      alkoholfrei:'',
      drink:'',
      song:''   
    }
  },
  watch: {
    questions() {
      this.scrollDown();
    },
    typing() {
      this.scrollDown();
    },
    
  },
  created() {
    
  },
  methods: {
    async deleteAntwort(antwort){

      if(antwort){
        let index = this.answers.findIndex(entry => entry.text === antwort.msg);
        if(index > 1){
          for (let i = index - 1; i >= 0; i--) { 
            if(this.answers[i].mtype ==="guest message"){
              let newanswer = this.answers[i];
              this.answers.splice(i);
              await this.addAntwort(newanswer);
              break;
            }
          }
        }
      }
    },
    async addAntwort(antwort) {
      if (Array.isArray(antwort.text)) {
        antwort.text = antwort.text.join(', ');
        this.answers.push(antwort);
      }else{
        this.answers.push(antwort);
      }
      let unsereAntwort = null;
      let neueFrage = null;
      if(antwort.qid === 'Q001'){
        await this.setTyping();
        unsereAntwort = {text: 'Freut uns, dass du unsere Website besuchst. Wir hätten ein paar Fragen an dich. Hast du kurz Zeit?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q201', qtype: 'select', text: 'Ja, klar.'};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q202', qtype: 'select', text: 'Nein, gerade ist es schlecht.'};
        this.questions.push(neueFrage);

       
      }else if(antwort.qid === 'Q202'){
        await this.setTyping();
        unsereAntwort = {text: 'Alles klar, dann schau doch später einfach nochmal vorbei.', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
      }else if(antwort.qid === 'Q201'){
        await this.setTyping();
        unsereAntwort = {text: 'Super!', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        unsereAntwort = {text: 'Kannst du uns als erstes deine E-Mail Adresse nennen, damit wir dir wichtige Infos per Mail senden können?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q300', qtype: 'input', text: '', placeholder: 'deine E-Mail'};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q300'){
        this.userMail = antwort.text.trim();
        await this.setTyping();
        unsereAntwort = {text: 'Danke dir!', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        unsereAntwort = {text: 'Hast du am 10. Mai 2025 Zeit und feierst mit uns unsere Hochzeit?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q401', qtype: 'select', text: 'Ja, ich komme gerne!', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q402', qtype: 'select', text: 'Nein, ich habe leider keine Zeit.', placeholder: ''};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q402'){
        this.zusage = false;
        await this.setTyping();
        unsereAntwort = {text: 'Schade, dass du keine Zeit hast.', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        unsereAntwort = {text: 'Danke für deine Rückmeldung.', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        this.postAnswer();
      }else if(antwort.qid==='Q401'){
        this.zusage=true;
        await this.setTyping();
        unsereAntwort = {text: 'Großartig, das freut uns!', mtype: 'our message'}
        
        await this.setTyping();
        unsereAntwort = {text: 'Kommst du alleine oder mit deinem Partner?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q501', qtype: 'select', text: 'Ich komme alleine', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q502', qtype: 'select', text: 'Ich bringe meinen Partner mit.', placeholder: ''};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q502'){
        await this.setTyping();
        unsereAntwort = {text: 'Das ist schön. Wie heißt dein Partner nochmal?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q601', qtype: 'input', text: '', placeholder: 'Name deines Partners'};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q601'){
        await this.setTyping();
        this.userPartner = antwort.text.trim();
        unsereAntwort = {text: 'Hast du und ' + this.userPartner + ' Kinder, die mit uns feiern wollen?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q701', qtype: 'select', text: 'Nein, keine Kinder.', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q702', qtype: 'input', text: 'Ja, ihre Namen sind: ', placeholder: 'Namen der Kinder'};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q701' || antwort.qid==='Q702' || antwort.qid==='Q501'){
        if(antwort.qid==='Q702'){
          this.kinder = antwort.text;
        }
        await this.setTyping();
        unsereAntwort = {text: 'Damit jeder das Abendessen genießen kann, möchten wir wissen, ob wir auf etwas Rücksicht nehmen müssen (z.B. Allergien, Vegetarier...)?', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        if(this.userPartner===''){
          neueFrage = {qid: 'Q801', qtype: 'select', text: 'Ich esse alles.', placeholder: ''};
        }else{
          neueFrage = {qid: 'Q801', qtype: 'select', text: 'Ich und ' + this.userPartner + ' essen alles.', placeholder: ''};
        } 
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q802', qtype: 'input', text: 'Ja, und zwar: ', placeholder: 'schreibe etwas'};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q801' || antwort.qid==='Q802'){
        this.essen=antwort.text;
        await this.setTyping();
        unsereAntwort = {text: 'Was uns schon immer interessiert hat...', mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: '... trinkst du eigentlich gerne Bier?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: '... trinkst du oder ' + this.userPartner +' eigentlich gerne Bier?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q901', qtype: 'select', text: 'Ja klar!', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q902', qtype: 'select', text: 'Nein, nicht so meins.', placeholder: ''};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q901'){
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Sehr gut! Welches Bier trinkst du am liebsten?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Sehr gut! Welches Bier trinkst du oder ' + this.userPartner + ' am liebsten?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q101', qtype: 'check', text: '', placeholder: '', selectable:['Pils', 'Helles', 'Weißbier', 'Jagdherren']};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q902'){
        
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Trinkst du gerne Wein?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Trinkst du oder ' + this.userPartner + ' gerne Wein?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q111', qtype: 'select', text: 'Ja, wir sind hier doch in Franken!', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q112', qtype: 'select', text: 'Nein, danke.', placeholder: ''};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q101'){
        this.bier = antwort.text;
        await this.setTyping();
        if(antwort.text === 'Nichts'){
          unsereAntwort = {text: 'Doch kein Bierdurst?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Ausgezeichnete Wahl!' , mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Trinkst du gerne Wein?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Trinkst du oder ' + this.userPartner + ' gerne Wein?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q111', qtype: 'select', text: 'Ja, wir sind hier doch in Franken!', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q112', qtype: 'select', text: 'Nein, danke.', placeholder: ''};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q111'){
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Welchen Wein trinkst du am liebsten?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Welchen Wein trinkst du oder ' + this.userPartner + ' am liebsten?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q121', qtype: 'check', text: '', placeholder: '', selectable:['Weißwein', 'Rotwein', 'Rotling oder Rosé']};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q112'){
        
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Willst du eine alkoholfreie Alternative zu Wein & Bier?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Willst du oder ' + this.userPartner + ' eine alkoholfreie Alternative zu Wein & Bier?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q131', qtype: 'select', text: 'Nein.', placeholder: ''};
        this.questions.push(neueFrage);
        if(this.userPartner===''){
          neueFrage = {qid: 'Q132', qtype: 'input', text: 'Ja, ich würde mich über folgendes freuen: ', placeholder: 'schreibe etwas'};
        }else{
          neueFrage = {qid: 'Q132', qtype: 'input', text: 'Ja, wir würden uns über folgendes freuen: ', placeholder: 'schreibe etwas'};
        }
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q121'){
        this.wein = antwort.text;
        await this.setTyping();
        if(antwort.text === 'Nichts'){
          unsereAntwort = {text: 'Okay.', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Das klingt vorzüglich!' , mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Willst du eine alkoholfreie Alternative zu Wein & Bier?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Willst du oder ' + this.userPartner + ' eine alkoholfreie Alternative zu Wein & Bier?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q131', qtype: 'select', text: 'Nein.', placeholder: ''};
        this.questions.push(neueFrage);
        if(this.userPartner===''){
          neueFrage = {qid: 'Q132', qtype: 'input', text: 'Ja, ich würde mich über folgendes freuen: ', placeholder: 'schreibe etwas'};
        }else{
          neueFrage = {qid: 'Q132', qtype: 'input', text: 'Ja, wir würden uns über folgendes freuen: ', placeholder: 'schreibe etwas'};
        }
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q131' || antwort.qid==='Q132' ){
        this.alkoholfrei = antwort.text;
        await this.setTyping();
        unsereAntwort = {text: 'Alles klar.' , mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Gibt es einen anderen Drink, der für dich nicht fehlen darf?', mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Gibt es einen anderen Drink, der für dich oder ' + this.userPartner + ' nicht fehlen darf?', mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        neueFrage = {qid: 'Q141', qtype: 'select', text: 'Nein, alles bestens.', placeholder: ''};
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q142', qtype: 'input', text: 'Ja, folgendes Getränk darf nicht fehlen: ', placeholder: 'schreibe etwas'};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q141' || antwort.qid==='Q142' ){
        this.drink = antwort.text;
        await this.setTyping();
        unsereAntwort = {text: 'Okay.' , mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        unsereAntwort = {text: 'Bei welchem Song kommt Partystimmung auf?' , mtype: 'our message'}
        this.answers.push(unsereAntwort);
        this.questions = [];
        if(this.userPartner===''){
          neueFrage = {qid: 'Q151', qtype: 'select', text: 'Ich lass mich überraschen', placeholder: ''};
        }else{
          neueFrage = {qid: 'Q151', qtype: 'select', text: 'Wir lassen uns überraschen', placeholder: ''};
        }
        this.questions.push(neueFrage);
        neueFrage = {qid: 'Q152', qtype: 'input', text: 'Dieses Lied muss auf eure Playlist: ', placeholder: 'schreibe etwas'};
        this.questions.push(neueFrage);
      }else if(antwort.qid==='Q151' || antwort.qid==='Q152' ){
        this.song=antwort.text;
        await this.setTyping();
        unsereAntwort = {text: 'Danke, dass du alle unsere Fragen beantwortet hast. Du erhälst gleich eine E-Mail von uns (schau am besten auch im Spam Ordner nach).' , mtype: 'our message'}
        this.answers.push(unsereAntwort);
        await this.setTyping();
        if(this.userPartner===''){
          unsereAntwort = {text: 'Vergesse nicht einen Eintrag in deinem Kalender zu machen. Wir freuen uns auf dich!' , mtype: 'our message'}
        }else{
          unsereAntwort = {text: 'Vergesst nicht einen Eintrag im Kalender zu machen. Wir freuen uns auf dich und ' + this.userPartner + '!' , mtype: 'our message'}
        }
        this.answers.push(unsereAntwort);
        this.questions = [];
        this.showCal = true;
        await this.postAnswer();
        await this.sendEmail();
      }
    },
    async setTyping() {
      this.typing = true;
      this.scrollDown();
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.typing = false;
      this.scrollDown();
    },
    showCalRows(){
      this.showCalRow = !this.showCalRow;
      this.scrollDown();
    },
    scrollDown() {
      this.$nextTick(() => {
        const element = document.getElementById("jump");
        if(element){
          element.scrollTop = element.scrollHeight;
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          
        }
      })
    },
    fetchGuests() {
      axios.get('../../getGuests.php')
        .then(response => {
          this.guests = response.data;
        })
        .catch(error => {
          console.error('Error fetching guests:', error);
        });
    },
    checkGuest(name){
      if(Array.isArray(this.guests)){
        const lowercaseGuests = this.guests;
        const exists = lowercaseGuests.some(guest =>  guest.Vorname.toLowerCase() === name.toLowerCase() || 
                                                      guest.Nachname.toLowerCase() === name.toLowerCase() ||
                                                      guest.Spitz1.toLowerCase() === name.toLowerCase() ||
                                                      guest.Spitz2.toLowerCase() === name.toLowerCase() 
                                                  );
        return exists;
      }
    },
    postAnswer(){
      axios.post('../../sendEntry.php', {
        Name: this.vorname.concat(" ", this.nachname),
        Mail: this.userMail,
        Zusage: this.zusage,
        Partner: this.userPartner,
        Kinder: this.kinder,
        Essen: this.essen,
        Bier: this.bier,
        Wein: this.wein,
        Alkoholfrei: this.alkoholfrei,
        Drink: this.drink,
        Song: this.song
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Es gab einen Fehler!', error);
      })
    },
    sendEmail() { 
      axios.post('../../sendMail.php', {
        Name: this.vorname,
        Mail: this.userMail,
        Zusage: this.zusage,
        Partner: this.userPartner
    })
      .then(response => { console.log(response.data); }) 
      .catch(error => { console.error("Fehler beim Senden der E-Mail:", error); 
      }); 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap{
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100vw;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  min-height: 100vh;
  }
.wrapChat{
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 10pt;
  width: 90vw;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-style: normal;
  padding-bottom: 10px;
}
#RSVP{
  font-size: 24pt;
}
#inputwrap{
  display: flex;
  flex-wrap: nowrap;
  max-width: 90vw;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-around;
}
.input{
  border-color: #f9dcdd;
  border-style: solid;
  border-width: 2px;
  overflow-wrap: break-word;
  color: #3a6090;
  background-color: #ffffff;
  align-self: flex-end;
  border-radius: 25px 25px 25px 25px;
  margin-left: 20px;
  flex:0 1 auto;
  display: flex;
  cursor: pointer;
}
.wrap { 
  flex-wrap: wrap; 
}
.input:hover{
  background: #f9dcdd;
}
.cals{
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
.calrow{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  cursor: pointer;
}

.loader {
  text-align: left;
}
.loader span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background: #3a6090;
  border-radius: 10px;
  animation: loader 0.8s infinite alternate;
}
.loader span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.loader span:nth-of-type(3) {
  animation-delay: 0.6s;
}
@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-4 21:31:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}


</style>
